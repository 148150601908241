<template>
  <div class="comservices">
    <!-- <div style="position: relative">
      <img
        class="bannerImg"
        src="../assets/images/main_business/banner.png"
        alt=""
      />
      <div class="banner">
        <p class="title">算力租赁服务</p>
        <p>
         南京智能计算中心利用强大的算力资源，不断为行业客户提供高性能算力租赁服务。现有AI算力资源达900Pops(INT16),算力租赁以PAAS为主要方式，明星算力资源为国产MLU370-M8加速卡。另有IAAS智算服务器可供选择。 </p>
      </div>
    </div> -->
    <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/main_business/banner.png')})`, 'background-size':'cover'}">
        <div class="banner">
            <p class="title" style="font-weight: 600;position: relative;bottom:0.2rem">算力租赁服务</p>
            <div class="banner-smallTitle"> 南京智能计算中心利用强大的算力资源，不断为行业客户提供高性能算力租赁服务。现有AI算力资源达900Pops(INT16),算力租赁以PAAS为主要方式，明星算力资源为国产MLU370-M8加速卡。另有IAAS智算服务器可供选择。</div>
        </div>
    </div>
    <div class="comservices-main">
        <div class="main">
            <div class="main-tops">
                <div class="top-top">
                    <div class="top-title">寒武纪MLU370-M8智能加速卡</div>
                    <div class="top-list">
                        <div class="top-list-single">
                            <div class="title">强劲算力</div>
                            <div class="subtitle">MLU370集群扩展性与GPU相当</div>
                            <img src="../assets/images/main_business/list1.png" alt="" class="img">
                            <div class="describe">采用32卡MLU370分布式训练 扩展性达到 93%</div>
                        </div>
                        <div class="top-list-single">
                            <div class="title">开箱即用</div>
                            <div class="subtitle">PyTorch/TensorFlow的平台迁移</div>
                            <img src="../assets/images/main_business/list2.png" alt="" class="img">
                            <div class="describe">基于TensorFlow/PyTorch训练; 多机多卡训练</div>
                        </div>
                    </div>
                </div>
                <div class="top-bottom">
                    <div class="top-bottom-title">完善的开发者社区及技术支持</div>
                    <div class="top-bottom-main">资源中心和学习中心; 开发者论坛和技术支持</div>
                </div>
            </div>
            <div class="main-mid">
                <div class="title">产品规格</div>
                <el-tabs v-model="activeName" >
                    <el-tab-pane label="智能加速卡" name="first">
                        <div class="mid-left">
                            <div class="mid-left-top">
                                <div class="title">寒武纪MLU370-M8</div>
                                <img src="../assets/images/main_business/mlu370.png" alt="" class="img">
                            </div>
                            <div class="mid-left-bottom">
                                <div class="mid-left-bottom-left"> 
                                    <div class="refer-title">AI算力</div>
                                    <div class="main">
                                        <div class="single">
                                            <div class="single-title">INT8</div>
                                            <div class="single-content">340TOPs</div>
                                        </div>
                                        <div class="single">
                                            <div class="single-title">INT16</div>
                                            <div class="single-content">170TOPs</div>
                                        </div>
                                        <div class="single">
                                            <div class="single-title">FP/BF16</div>
                                            <div class="single-content">128TFLOPs</div>
                                        </div>
                                        <div class="single">
                                            <div class="single-title">FP32</div>
                                            <div class="single-content">32TFLOPs</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mid-left-bottom-mid">
                                    <div class="refer-title">内存容量</div>
                                    <div class="refer-content">48GB LPDDRS</div>
                                </div>
                                <div class="mid-left-bottom-right">
                                    <div class="refer-title">内存宽带</div>
                                    <div class="refer-content">307GB/s</div>
                                </div>
                            </div>
                        </div>
                        <div class="mid-mid">
                            <div class="mid-mid-top">
                                <div class="title">寒武纪MLU290-M5</div>
                            </div>
                            <div class="mid-mid-bottom">
                                <div class="title">AI算力</div>
                                <div class="desc">INT8:512TOPs / INT16:256TOPs</div>
                                <div class="detail-desc">内存容量：32GB HBM2；</div>
                                <div class="detail-desc">内存位宽：4096 bit；</div>
                                <div class="detail-desc">内存带宽：1224 GB/s；</div>

                            </div>
                        </div>
                        <div class="mid-right">
                            <div class="mid-mid-top">
                                <div class="title">寒武纪MLU270-X5K</div>
                            </div>
                            <div class="mid-mid-bottom">
                                <div class="title">AI算力</div>
                                <div class="desc">INT8:140TOPs</div>
                                <div class="detail-desc">内存容量：32GB DDR4, ECC;</div>
                                <div class="detail-desc">内存位宽：256-bit；</div>
                                <div class="detail-desc">内存带宽：102GB/s；</div>

                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="IAAS智算服务器" name="second">
                        <div class="server">
                            <div class="server-title">
                                IAAS智算服务器   
                            </div>
                            <div class="server-content">
                                <div class="server-content-top">
                                    <div class="server-content-top-single">
                                        <div class="single-title">CPU</div>
                                        <div class="single-content">Intel_6240R_Xeon_2.4GHz * 2</div>
                                    </div>
                                    <div class="server-content-top-single">
                                        <div class="single-title">内存</div>
                                        <div class="single-content">32G_DDR4-2933MHz * 16</div>
                                    </div>
                                    <div class="server-content-top-single">
                                        <div class="single-title">系统盘</div>
                                        <div class="single-content">SSD_480G * 2      RAID1</div>
                                    </div>
                                    <div class="server-content-top-single">
                                        <div class="single-title">数据盘</div>
                                        <div class="single-content">SSD_960G * 4      RAID5</div>
                                    </div>
                                </div>
                                <div class="server-content-bottom">存储方式：分布式存储 、全闪存储</div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="main-bottom">
                <div class="title">应用场景</div>
                <img src="../assets/images/main_business/foot1.png" alt="" class="foot-img">
            </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
    watch: {},
    data () {
        return {
            activeName: 'first'
        }
    },
}
</script>
<style lang="scss" >
.main-mid .el-tab-pane{
    display: flex;
    justify-content: space-between;
}
.banner-img{
    width: 100%;
    height: 2.8rem;
    position: relative;
    background-size: cover;
}
</style>
<style lang="scss" >
.comservices {
  .bannerImg {
    height: 2.6rem;
  }
  .banner {
    left: 18.75%;
    transform: translateY(-60%);
    right: 34%;
    font-size: 0.14rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    p:nth-child(1) {
      display: inline-block;
      line-height: 0.2rem;
      font-size: 0.4rem;
    }
    p:nth-child(2) {
        font-size: 0.2rem;
        margin-top: 0.16rem;
        display: block;
        border-bottom: none;
    }
  }
  .comservices-main{
      width: 100%;
      padding:0.6rem 0;
      background: #F5F5F5;
      .main{
          width:12rem;
          margin:0 auto;
          .main-tops{
            .top-title{
                font-size: 0.24rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                line-height: 1rem; 
            }
            .top-list{
                display: flex;
                justify-content: space-between;
                .top-list-single{
                    width: 49%;
                    padding-top:0.2rem;
                    background: #ffffff;
                    .title{
                        font-size: 0.16rem;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #333333;
                        padding-left:0.2rem;
                        border-left:0.01rem solid #0061E3;
                    }
                    .subtitle{
                        margin-top:0.09rem;
                        font-size: 0.14rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        padding:0 0.2rem
                    }
                    .img{
                        width: 5.5rem;
                        height: 2rem;
                        margin: 0.2rem;
                    }
                    .describe{
                        padding: 0 0.2rem 0.2rem 0.2rem;
                        font-size: 0.14rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #0061E3;
                    }
                }
            }
          }
          .top-bottom{
              margin-top: 0.2rem;
            //   height: 1rem;
              background: #ffffff;
              .top-bottom-title{
                    font-size: 0.16rem;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0.56rem;
                    margin:0 0.2rem
                }
                .top-bottom-main{
                    font-size: 0.14rem;
                    font-family: Adobe Heiti Std;
                    font-weight: normal;
                    color: #0066CC;
                    padding:0rem 0.2rem 0.29rem 0.2rem
                    // line-height:0.67rem;
                } 
          }
        }
        .main-mid{
            margin-top: 0.6rem;
           .title{  
                font-size: 0.32rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           } 
            .mid-left{
                width: 5.8rem;
                font-size: 0.18rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #011633;
                line-height: 0.68rem;
                display: inline-block;
                .mid-left-top{
                    display: flex;
                    justify-content: space-between;
                    height: 0.68rem;
                    background: linear-gradient(90deg, #C0D6F3, #E1E9F0);
                    .title{
                        font-size: 18px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #011633;
                        margin-left: 0.2rem;
                    }
                    .img{
                        height: 0.71rem;
                        width: 0.76rem;
                        margin-right: 0.4rem;
                    }
                }
                .mid-left-bottom{
                    padding:0.2rem;
                    margin-top: 0.15rem;
                    width: 5.4rem;
                    height: 1.4rem;
                    background: #FFFFFF;
                    border: 0.01rem solid #C8D5E1;
                    display: flex;
                    justify-content: space-between;
                    .mid-left-bottom-left{
                        width:50%;
                        .refer-title{
                            text-align: left;
                            font-size: 0.14rem;
                            line-height: 0.14rem;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #0061E3;
                        }
                        .main{
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            .single{
                                width: 50%;
                                margin-top: 0.2rem;
                                font-size: 0.16rem;
                                line-height: 0.16rem;
                                font-family: Source Han Sans CN;
                                font-weight: 500;
                                .single-title{
                                    color: #A8A8A8;
                                }
                                .single-content{
                                    margin-top: 0.08rem;
                                    color: #333333;
                                }
                            }
                        }
                    }
                    .mid-left-bottom-mid{
                        width: 25%;
                        .refer-title{
                            text-align: left;
                            font-size: 0.14rem;
                            line-height: 0.14rem;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #0061E3;
                        }
                        .refer-content{ 
                            font-size: 0.16rem;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                        }
                    }
                    .mid-left-bottom-right{
                        width: 25%;
                        .refer-title{
                            text-align: left;
                            font-size: 0.14rem;
                            line-height: 0.14rem;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            color: #0061E3;
                        }
                        .refer-content{ 
                            font-size: 0.16rem;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                        }
                    }
                }
            } 
            .mid-mid{
                width: 2.9rem;
                font-size: 0.18rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #011633;
                line-height: 0.68rem;
                display: inline-block;
                margin-left: 0.2rem;
                .mid-mid-top{
                    height: 0.68rem;
                    background: linear-gradient(90deg, #C0D6F3, #E1E9F0);
                    .title{
                        text-align: left;
                        font-size: 0.18rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #011633;
                        margin-left: 0.2rem;
                    }
                }
                .mid-mid-bottom{
                    padding:0.2rem;
                    margin-top: 0.15rem;
                    width: 2.5rem;
                    height: 1.4rem;
                    background: #FFFFFF;
                    border: 1px solid #C8D5E1;
                    font-size: 0.14rem;
                    font-family: Source Han Sans CN;
                    line-height: 0.14rem;
                    .title{
                    font-size: 0.14rem;
                        font-weight: bold;
                        color: #0061E3;
                        text-align: left;
                    }
                    .desc{
                        padding:0.2rem 0;
                        font-weight: 500;
                        color: #333333;
                    }
                    .detail-desc{
                        line-height: 0.16rem;   
                        color: #333333;
                    }
                }
            }
            .mid-right{
                width: 2.9rem;
                font-size: 0.18rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #011633;
                line-height: 0.68rem;
                display: inline-block;
                margin-left: 0.2rem;
                .mid-mid-top{
                    height: 0.68rem;
                    background: linear-gradient(90deg, #C0D6F3, #E1E9F0);
                    .title{
                        text-align: left;
                        font-size: 0.18rem;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #011633;
                        margin-left: 0.2rem;
                    }
                }
                .mid-mid-bottom{
                    padding:0.2rem;
                    margin-top: 0.15rem;
                    width: 2.5rem;
                    height: 1.4rem;
                    background: #FFFFFF;
                    border: 1px solid #C8D5E1;
                    font-size: 0.14rem;
                    font-family: Source Han Sans CN;
                    line-height: 0.14rem;
                    .title{
                    font-size: 0.14rem;
                        font-weight: bold;
                        color: #0061E3;
                        text-align: left;
                    }
                    .desc{
                        padding:0.2rem 0;
                        font-weight: 500;
                        color: #333333;
                    }
                    .detail-desc{
                        line-height: 0.16rem;   
                        color: #333333;
                    }
                }
            }
            .server{
                width: 100%;
                .server-title{
                    background: linear-gradient(90deg, #C0D6F3, #E1E9F0);
                    font-size: 0.18rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #011633;
                    line-height: 0.68rem;
                    padding-left: 0.2rem;
                }
                .server-content{
                    width: 100%;
                    background: #FFFFFF;
                    border: 0.01rem solid #C8D5E1;
                    margin-top: 0.15rem;
                    padding:0.2rem;
                    .server-content-top{
                        display: flex;
                        justify-content: space-between;
                        .server-content-top-single{
                            font-size: 0.14rem;
                            font-family: Source Han Sans CN;
                            width:25%;
                            .single-title{
                                font-weight: bold;
                                color: #0061E3; 
                            }
                            .single-content{
                                padding-top: 0.2rem;
                                font-weight: 500;
                                color: #333333;
                            }
                        }
                    }
                    .server-content-bottom{
                        padding-top: 0.38rem;
                        font-size: 0.14rem;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #A8A8A8;
                    }
                }
            }
        }
        .main-bottom{
            margin-top: 0.6rem;
           .title{  
                font-size: 0.32rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0061E3;
                text-align:center;
                margin-bottom: 0.21rem;
           }
           .foot-img{
               height: 3.6rem;
               width: 100%;
           } 
        }
    }
}
</style>
